import { KEYCLOAK_CLIENT, KEYCLOAK_ENABLED, KEYCLOAK_REALM, KEYCLOAK_URL } from '@m/config/env';
import { AnyFunction } from '@m/types';
import { User } from '@m/types/api';
import Keycloak, { KeycloakOnLoad } from 'keycloak-js';

let keycloakClient: Keycloak | null = null;

export const KeycloakService = {
  /**
   * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
   *
   * @param onLoad
   * @param onAuthenticatedCallback
   */
  initKeycloak: async (onLoad: KeycloakOnLoad, onAuthenticatedCallback?: () => void) => {
    // Keycloak references window.document so wait until init is called to initialize.
    // TODO: Check is keycloak client can be moved out of the service object with keycloak v26.
    keycloakClient = new Keycloak({
      url: KEYCLOAK_URL,
      realm: KEYCLOAK_REALM,
      clientId: KEYCLOAK_CLIENT,
    });

    // microsoft login hangs and then fails if we initialize keycloak. we don't need to initialize if we're in the login-redirect page
    if (KEYCLOAK_ENABLED && window.location.pathname !== '/login-redirect') {
      keycloakClient
        .init({ onLoad })
        .then((authenticated) => {
          if (!authenticated) {
            // eslint-disable-next-line no-console
            console.log('User is not authenticated');
          }
          onAuthenticatedCallback?.();
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    } else {
      onAuthenticatedCallback?.();
    }
  },

  doLogin: () => keycloakClient?.login(),

  doLogout: () => keycloakClient?.logout(),

  getToken: () => keycloakClient?.token,

  getTokenParsed: () => keycloakClient?.tokenParsed,

  isLoggedIn: () => !!keycloakClient?.token,

  updateToken: (successCallback: AnyFunction) =>
    keycloakClient?.updateToken(5).then(successCallback).catch(KeycloakService.doLogin),

  getUsername: () => keycloakClient!.tokenParsed?.preferred_username,

  // hasRole: (roles) => roles.some((role) => keycloakClient.hasRealmRole(role)),
  getUserObject: () =>
    ({
      id: keycloakClient?.tokenParsed?.sub,
      email: keycloakClient?.tokenParsed?.email,
      firstName: keycloakClient?.tokenParsed?.given_name,
      lastName: keycloakClient?.tokenParsed?.family_name,
    }) as Partial<User>,
};
