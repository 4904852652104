// TODO: Separate enum store by app?
import { CANSelecOption, USASelecOption } from '@m/constants/Regions';
import { applyReducers } from '@m/effector-utils';
import { IdNameObject, Option } from '@m/types';
import {
  cadProvinceNameMap,
  usaStateEquivalentToNameMap,
  usaStateNameMap,
} from '@m/utils/stateProvinceHelperUtil';
import { createStore, Effect } from 'effector';

import {
  fetchEnums,
  fetchLPStates,
  fetchInvoiceLineItemProductTypes,
  fetchOperator,
  fetchSubscriptionCancellationReasons,
  fetchViolationCloseReasons,
  fetchViolationStatuses,
} from './actions';

import type { DoneHandler } from '@m/effector-utils';
import type { APIResponse } from '@m/utils/http';

type State = {
  licensePlateState: IdNameObject[];
  permission: IdNameObject[];
  permissionGroup: IdNameObject[];
  siteEquipmentType: IdNameObject[];
  siteEquipmentApproachSettingType: IdNameObject[];
  siteLocationType: IdNameObject[];
  siteStatus: IdNameObject[];
  subscriptionPlanClass: IdNameObject[];
  operators: (IdNameObject & { uuid: string })[];
  subscriptionCancellationReasons: IdNameObject[];
  violationStatuses: IdNameObject[];
  violationCloseReason: IdNameObject[];
  productTypeOptions: { value: string; label: string }[] | null;
};

const initialState: State = {
  licensePlateState: [],
  permission: [],
  permissionGroup: [],
  siteEquipmentType: [],
  siteEquipmentApproachSettingType: [],
  siteLocationType: [
    {
      id: 1,
      name: 'Garage',
    },
    {
      id: 2,
      name: 'Lot',
    },
    {
      id: 3,
      name: 'Stand',
    },
    {
      id: 4,
      name: 'Event',
    },
    {
      id: 5,
      name: 'Other',
    },
  ],
  siteStatus: [],
  subscriptionPlanClass: [],
  operators: [],
  subscriptionCancellationReasons: [],
  violationStatuses: [],
  violationCloseReason: [],
  productTypeOptions: null,
};
const store = createStore(initialState);

type Reducers = {
  fetchEnums: {
    action: Effect<Parameters<typeof fetchEnums>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchEnums>[0], State>;
  };
  fetchLPStates: {
    action: Effect<Parameters<typeof fetchLPStates>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchLPStates>[0], State>;
  };
  fetchInvoiceLineItemProductTypes: {
    action: Effect<Parameters<typeof fetchInvoiceLineItemProductTypes>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchInvoiceLineItemProductTypes>[0], State>;
  };
  fetchOperator: {
    action: Effect<Parameters<typeof fetchOperator>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchOperator>[0], State>;
  };
  fetchSubscriptionCancellationReasons: {
    action: Effect<Parameters<typeof fetchSubscriptionCancellationReasons>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchSubscriptionCancellationReasons>[0], State>;
  };
  fetchViolationStatuses: {
    action: Effect<Parameters<typeof fetchViolationStatuses>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchViolationStatuses>[0], State>;
  };
  fetchViolationCloseReasons: {
    action: Effect<Parameters<typeof fetchViolationCloseReasons>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchViolationCloseReasons>[0], State>;
  };
};

// 53 states and territories
export const END_OF_USA_STATES_LIST_INDEX = 53;

/**
 * Since the ordering is important on the frontend, we are not adding a fallback if we find a newly added state that's missing on the frontend
 * If we want to add a new option, consider refactoring and getting the list from the backend. Include iOS in discussions
 *
 * 1. USA option
 * 2. USA States
 * 3. USA equivalents (Diplomat, Government, Military)
 * 4. Extra OTHER (id: 530)
 * 5. CAD option
 * 6. CAD Province
 * 7. OTHER
 *
 * TODO: Get the list we want to show from the backend
 */
export function setupLicensePlateOrder(plateStates: IdNameObject[]) {
  const adjustedStates = plateStates.slice(1);
  const otherOptionId = adjustedStates.findIndex(
    (each: Option) => each?.name?.toLowerCase() === 'other',
  );
  const otherOption = adjustedStates.splice(otherOptionId, 1);

  const usaStates = plateStates.filter(({ name }) => !!usaStateNameMap[name]);
  const cadTerritory = plateStates.filter(({ name }) => !!cadProvinceNameMap[name]);
  const usaEquivalentStates = plateStates.filter(({ name }) => !!usaStateEquivalentToNameMap[name]);

  // USA and CAN are for view layer only they are not selectable and are not options returned to api
  // adding an extra "other" option that will be adjusted before we send the data. id of 530 will be adjusted to 53
  return [
    USASelecOption, // #1
    ...usaStates, // #2
    ...usaEquivalentStates, // #3
    { id: 530, name: 'OTHER' }, // #4
    CANSelecOption, // #5
    ...cadTerritory, // #6
    otherOption[0], // #7
  ];
}

export const reducers: Reducers = {
  fetchEnums: {
    action: fetchEnums,
    done: (state, { result: { success, data } }) => {
      // adjusting states to allow grouping by country.
      if (!success) return state;
      const { licensePlateState, ...restOfData } = data;
      return {
        ...state,
        ...restOfData,
      };
    },
  },
  fetchLPStates: {
    action: fetchLPStates,
    done: (state, { result: { success, data } }) => {
      // adjusting states to allow grouping by country.
      if (!success) return state;
      return {
        ...state,
        licensePlateState: setupLicensePlateOrder(data),
      };
    },
  },

  fetchInvoiceLineItemProductTypes: {
    action: fetchInvoiceLineItemProductTypes,
    done: (state, { result: { success, data } }) => {
      const productTypeFromResposne = data.productTypes;
      const productTypeOptions = productTypeFromResposne
        .filter((type: string) => {
          const excludedTypes = ['UNKNOWN_PRODUCT_TYPE', 'SUBSCRIPTION', 'OTHER'];

          return !excludedTypes.includes(type) && !type.includes('UNKNOWN_ENUM_VALUE_');
        })
        .map((type: string, id: number) => {
          const readableType = type
            .split('_')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
          return {
            id,
            value: type,
            label: readableType,
          };
        });
      return {
        ...state,
        productTypeOptions,
      };
    },
  },
  fetchOperator: {
    action: fetchOperator,
    done: (state, { result: { success, data } }) => ({
      ...state,
      operators: success ? data : state.operators,
    }),
  },
  fetchSubscriptionCancellationReasons: {
    action: fetchSubscriptionCancellationReasons,
    done: (state, { result: { success, data } }) => ({
      ...state,
      subscriptionCancellationReasons: success
        ? data.reasons
        : state.subscriptionCancellationReasons,
    }),
  },
  fetchViolationStatuses: {
    action: fetchViolationStatuses,
    done: (state, { result: { success, data } }) => ({
      ...state,
      violationStatuses: success ? data : state.violationStatuses,
    }),
  },
  fetchViolationCloseReasons: {
    action: fetchViolationCloseReasons,
    done: (state, { result: { success, data } }) => ({
      ...state,
      violationCloseReason: success ? data : state.violationCloseReason,
    }),
  },
};

export default applyReducers({ store, reducers });
