/**
 * TODO:
 *   1. decouple width change logic to root layout,
 *      so this component is purely responsive to its parent width.
 */

import { useState } from 'react';

import {
  EnvironmentOutlined,
  FlagOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import EnterpriseIcon from '@m/assets/svg/enterprise';
import LocationIcon from '@m/assets/svg/location-group';
import Logo from '@m/assets/svg/logo';
import PoliceOfficer from '@m/assets/svg/police-officer-1';
import Wordmark from '@m/assets/svg/wordmark';
import EnvironmentBadge from '@m/components/EnvironmentBadge';
import { KEYCLOAK_ENABLED } from '@m/config/env';
import { isProd } from '@m/constants/FeatureFlags';
import { fontDefault } from '@m/styled/fonts';
import { RoleName } from '@m/types/api/iam/Iam';
import { Dropdown, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import useSuperAdminCheck from 'apps/admin/hooks/useSuperAdminCheck';
import { hasRoles } from 'apps/admin/permissions/RouteManager';
import { logout } from 'apps/admin/state/auth';

import { LogoArea, LogoWrap, MenuItemContent, Nav, WordmarkWrap } from './Navigation.styled';

import type { MenuProps } from 'antd/lib/menu';

export default function Navigation() {
  const history = useHistory();

  const { pathname } = useLocation();
  const hideNavBar = pathname.includes('/site-survey') || pathname.includes('/add-zone');
  const defaultCollapsed = useMedia('(max-width: 1080px');
  const smallView = useMedia('(max-width: 420px)');
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const isSuperAdminPerm = useSuperAdminCheck();

  const topLevelRoute = pathname.split('/')[1];

  const onCollapse = (isCollapsed: boolean, type: string) => {
    if (type !== 'responsive') {
      setCollapsed(isCollapsed);
    }
  };

  const superAdminHiddenMenu: MenuProps['items'] = [
    // on click redirect app to notification-center
    hasRoles([RoleName.MANAGER])
      ? {
          label: 'Notification Center',
          key: '1',
          onClick: () => {
            history.push('/notification-center');
          },
        }
      : null,
    {
      label: 'Visit Center',
      key: '2',
      onClick: () => {
        history.push('/visit-center');
      },
    },
    hasRoles([RoleName.MANAGER])
      ? {
          label: 'Visit Charge Management',
          key: '3',
          onClick: () => {
            history.push('/visit-charge-management');
          },
        }
      : null,
    !isProd && KEYCLOAK_ENABLED && hasRoles([RoleName.MANAGER])
      ? {
          label: 'Manager Cleanup',
          key: '4',
          onClick: () => {
            history.push('/manager-cleanup');
          },
        }
      : null,
  ];

  const menuItems: MenuProps['items'] = [
    KEYCLOAK_ENABLED &&
      hasRoles([RoleName.OPERATIONAL_ADMIN, RoleName.CUSTOMER_SUPPORT]) && {
        key: 'operators',
        icon: <UserOutlined />,
        label: (
          <MenuItemContent to="/operators">
            <span>Operators</span>
          </MenuItemContent>
        ),
      },
    hasRoles([RoleName.MANAGER, RoleName.CUSTOMER_SUPPORT]) && {
      key: 'enterprise',
      icon: (
        <i className="anticon">
          <EnterpriseIcon fill="currentColor" width="1em" height="1em" />
        </i>
      ),
      label: (
        <MenuItemContent to="/enterprise">
          <span>Enterprises</span>
        </MenuItemContent>
      ),
    },
    hasRoles([RoleName.MANAGER, RoleName.CUSTOMER_SUPPORT]) && {
      key: 'locations',
      icon: (
        <i className="anticon">
          <LocationIcon fill="currentColor" width="1em" height="1em" />
        </i>
      ),
      label: (
        <MenuItemContent to="/locations">
          <span>Locations</span>
        </MenuItemContent>
      ),
    },
    hasRoles([RoleName.MANAGER, RoleName.CUSTOMER_SUPPORT]) && {
      key: 'zones',
      icon: <EnvironmentOutlined />,
      label: (
        <MenuItemContent to="/zones">
          <span>Zones</span>
        </MenuItemContent>
      ),
    },
    hasRoles([RoleName.MANAGER, RoleName.CUSTOMER_SUPPORT]) && {
      key: 'users',
      icon: <UserOutlined />,
      label: (
        <MenuItemContent to="/users">
          <span>Users</span>
        </MenuItemContent>
      ),
    },
    hasRoles([
      RoleName.SUPER_ADMIN,
      RoleName.LEADER,
      RoleName.OPERATIONAL_ADMIN,
      RoleName.CUSTOMER_SUPPORT,
    ]) && {
      key: 'customers',
      icon: <TeamOutlined />,
      label: (
        <MenuItemContent to="/customers">
          <span>Customers</span>
        </MenuItemContent>
      ),
    },
    hasRoles([RoleName.MANAGER, RoleName.CUSTOMER_SUPPORT]) && {
      key: 'enforcement',
      icon: (
        <i className="anticon">
          <PoliceOfficer height={14} width={14} />
        </i>
      ),
      label: (
        <MenuItemContent to="/enforcement/notices">
          <span>Enforcement</span>
        </MenuItemContent>
      ),
    },
    hasRoles([RoleName.SUPER_ADMIN]) &&
      isSuperAdminPerm && {
        key: 'feature-flags',
        icon: <FlagOutlined />,
        label: (
          <MenuItemContent to="/feature-flags">
            <span>Feature Flags</span>
          </MenuItemContent>
        ),
      },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: <span>Log Out</span>,
      onClick: () => {
        logout();
      },
    },
  ].filter((item) => item !== false);

  if (hideNavBar) return null;
  return (
    <>
      <EnvironmentBadge
        compact={collapsed}
        position="fixed"
        top="100vh"
        left={0}
        marginTop={-80}
        marginLeft={6}
        zIndex={4}
        letterSpacing="0.125em"
        width={collapsed ? 50 : 174}
        css={`
          /* Overrides for inherited antd styles */
          padding: 0 !important;
          transition: width 150ms ease !important;
        `}
      />
      <Nav
        style={{
          // Styles should be applied here instead of in Navigation.styled.ts in order to override
          // the default styles of the antd component.
          userSelect: 'none',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          insetInlineStart: 0,
          top: 0,
          bottom: 0,
          zIndex: 3,
          scrollbarWidth: 'thin',
          scrollbarGutter: 'stable',
          overflowX: 'hidden',
          fontFamily: fontDefault,
        }}
        data-testid="Navigation"
        collapsible
        breakpoint="sm"
        collapsedWidth={smallView ? 0 : 80}
        $expanded={!collapsed}
        defaultCollapsed={defaultCollapsed}
        onCollapse={onCollapse}
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true);
          }
        }}
      >
        <LogoArea>
          <LogoWrap>
            <Dropdown
              disabled={
                !isSuperAdminPerm ? !hasRoles([RoleName.CUSTOMER_SUPPORT]) : !isSuperAdminPerm
              }
              menu={{ items: superAdminHiddenMenu }}
              trigger={['contextMenu']}
            >
              <Logo />
            </Dropdown>
          </LogoWrap>
          <WordmarkWrap $expanded={!collapsed}>
            <Wordmark />
          </WordmarkWrap>
        </LogoArea>
        <Menu
          theme="dark"
          defaultSelectedKeys={['enterprise']}
          selectedKeys={[topLevelRoute, `${topLevelRoute}-list`]}
          mode="inline"
          items={menuItems}
        />
      </Nav>
    </>
  );
}
