/**
 * Reads in environment variables here
 *
 * Since our app isn't ready to move everything to environment variables,
 * we need to compute some of the client and API base URLs for different domains
 * e.g. localhost:####, app.dev.metrop.io, app.metropolis.io
 *
 * Next phase, we should retrieve all client and site API urls from environment variables and remove the reliance of the utility functions
 */

import { isLocalDev, isLocalStaging } from '@m/constants/FeatureFlags';
import { AppName } from '@m/types/AppName';

export type AppSubdomain = AppName | 'app' | 'manager';

export function getAppBaseUrl(appSubdomain: AppSubdomain) {
  if (appSubdomain === 'admin') return ADMIN_APP_URL;
  if (appSubdomain === 'manager') return MANAGER_APP_URL;
  if (appSubdomain === 'app' || appSubdomain === 'customer') return CUSTOMER_APP_URL;
  if (appSubdomain === 'specialist') return SPECIALIST_APP_URL;
  if (appSubdomain === 'pay') return PAY_APP_URL;
  if (appSubdomain === 'portal') return PORTAL_APP_URL;
  if (appSubdomain === 'intake') return INTAKE_APP_URL;
  if (appSubdomain === 'validation') return VALIDATION_APP_URL;
  if (appSubdomain === 'request') return REQUEST_APP_URL;
  return null;
}

// Keycloak
const KEYCLOAK_APPS = ['manager', 'specialist', 'intake', 'portal'];
export const KEYCLOAK_ENABLED = !!(
  process.env.REACT_APP_KEYCLOAK_ENABLED === 'true' &&
  (window.location.host.match(new RegExp(`^(${KEYCLOAK_APPS.join('|')}).[a-z]+.metrop.io$`)) ||
    window.location.host.match(new RegExp(`^(${KEYCLOAK_APPS.join('|')}).metropolis.io$`)) ||
    window.location.host.match(/^localhost:[\d]+$/))
);
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL as string;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM as string;
export const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT as string;

// Client Applications
export const ADMIN_APP_URL = process.env.REACT_APP_ADMIN_APP_URL as string;
export const CUSTOMER_APP_URL = process.env.REACT_APP_CUSTOMER_APP_URL as string;
export const INTAKE_APP_URL = process.env.REACT_APP_INTAKE_APP_URL as string;
export const MANAGER_APP_URL = process.env.REACT_APP_MANAGER_APP_URL as string;
export const PAY_APP_URL = process.env.REACT_APP_PAY_APP_URL as string;
export const PORTAL_APP_URL = process.env.REACT_APP_PORTAL_APP_URL as string;
export const REQUEST_APP_URL = process.env.REACT_APP_REQUEST_APP_URL as string;
export const SPECIALIST_APP_URL = process.env.REACT_APP_SPECIALIST_APP_URL as string;
export const VALIDATION_APP_URL = process.env.REACT_APP_VALIDATION_APP_URL as string;

// Site API
// Goal is:
// export const SITE_API_BASE_URL = process.env.REACT_APP_SITE_API_BASE_URL;
function getSiteApi() {
  if (isLocalDev) {
    return process.env.REACT_APP_SITE_API_BASE_URL_DEV;
  }

  if (isLocalStaging) {
    return process.env.REACT_APP_SITE_API_BASE_URL_STAGING;
  }

  return process.env.REACT_APP_SITE_API_BASE_URL;
}

export const SITE_API_BASE_URL = getSiteApi();

// See Mapbox and github secrets for token details.
// Production uses a token only with access to metropolis.io and metrop.io.
// Staging and Dev use a dummy token to minimize costs.
// Localhost uses a token only with access to localhost.
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;
