import tilePattern from '@m/assets/svg/tile-pattern.svg';
import { fadeIn, slideUpFadeIn } from '@m/styled/keyframes';
import withStyledSystem, { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';
import { Layout } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

const { Content } = Layout;

export const AntdOverrides = createGlobalStyle`
  :root {
    background-color: ${(props) => props.theme.colors.grey2};
  }

  /* Override Ant Design: */
  #root {
    .ant-layout-sider {
      background: #111114 !important;

      & .ant-layout-sider-trigger {
        background-color: #040407 !important;
      }
    }

    .ant-menu-dark {
      background-color: #111114 !important;

      & .ant-menu-sub,
      & .ant-menu-inline.ant-menu-sub {
        background-color: #040407 !important;
      }
    }

    .ant-menu-item {
      letter-spacing: 0.02em !important;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
      background-color: #2f2d78 !important;
      color: #fff !important;
      font-weight: 500 !important;
      letter-spacing: 0.015em !important;
      svg path {
        fill: #fff !important;
      }
    }

    .ant-pagination-item:hover {
      background-color: #fff !important;
      border-color: #9592fc !important;

      & a {
        color: #5f59ff !important;
        font-weight: 700 !important;
      }
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:hover {
      border-color: #5f59ff !important;
      background-color: #fff !important;
      & a {
        color: #5f59ff !important;
      }
    }

    .ant-pagination-item a {
      font-weight: 500 !important;
    }

    .ant-pagination-item-active a {
      font-weight: 700 !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #f6f6ff !important;
    }

    .ant-select-item {
      user-select: none;
    }

    .ant-btn {
      font-weight: 500 !important;
    }

    .ant-btn.ant-btn-primary {
      border: 1px solid rgba(0, 0, 0, 0.225) !important;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25) !important;
      background-color: #5f59ff !important;
      color: #fff !important;
      border-radius: 5px !important;
      letter-spacing: 0.02em !important;
      -webkit-font-smoothing: antialiased !important;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.225);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        background-color: #5f59ff !important;
        border-radius: 5px;
      }

      &:hover {
        background-color: #5f59ff !important;
        background-image: linear-gradient(
          rgba(255, 255, 255, 0.06),
          rgba(255, 255, 255, 0.06)
        );
        border: 1px solid rgba(0, 0, 0, 0.225);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      }

      &:active {
        background-color: #5f59ff !important;
        border: 1px solid rgba(0, 0, 0, 0.225);
        background-image: linear-gradient(rgba(0, 0, 0, 0.225), rgba(0, 0, 0, 0.225));
        box-shadow: none;
        color: rgba(255, 255, 255, 0.7);
        transition: none;
      }

      &:disabled {
        color: #fff !important;
        opacity: 0.35 !important;
        background-image: none !important;
        border: 1px solid #5f59ff !important;
        box-shadow: none !important;
        filter: grayscale(0.25);
      }

      &.ant-btn-background-ghost {
        border: 1px solid #5f59ff;
        box-shadow: none;
        color: #5f59ff;

        &:hover {
          background-color: #f6f6ff !important;
        }

        &:active {
          background-color: #e2dfff !important;
        }
      }
    }

    .ant-table-wrapper,
    .ant-table-container {
      width: 100% !important;

      & .ant-table-content {
        width: 100%;

        & table,
        & thead,
        & thead > tr,
        & tbody,
        & tbody > tr {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > th,
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > th,
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background-color: var(--colors-white);
      background-image: radial-gradient(
        ellipse at bottom,
        rgba(246,246,255,1.00),
        rgba(246,246,255,0.25)
      );

      &:only-child {
        background-image: none !important;
      }
    }

    tr:not(.ant-table-row-selected) > td.ant-table-column-sort {
      background-color: var(--colors-white) !important;
    }

    .ant-pagination .ant-pagination-item-lin {
      user-select: none !important;
    }
    .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination .ant-pagination-next .ant-pagination-item-link {
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255,0) !important;
      border: 0 !important;
      font-size: 16px !important;
      color: var(--colors-blue7) !important;
      &:hover {
        background-color: rgba(255,255,255,1) !important;
        border: 1px solid var(--colors-brand) !important;
        color: var(--colors-brand) !important;
      }
    }

    .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
      opacity: 0.35;
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: var(--colors-blue7) !important;
      user-select: none !important;
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: var(--colors-brand) !important;
    }

    .ant-calendar-picker-input.ant-input {
      border: none;
      outline: none !important;
      padding-left: 0;
      border-radius: 0;
      border-bottom: 1px solid var(--colors-grey3);
      box-shadow: none;

      &:hover {
        border-bottom: 1px solid var(--colors-grey3) !important;
      }
    }

    .ant-upload-picture-card-wrapper {
      width: auto !important;
      max-width: 100% !important;
    }
  }
`;

export const AdminAppBox = styled.div`
  min-height: 100vh;

  & > section {
    min-height: 100vh;
  }
`;

export const ListPageWrapper = styled.div<StyledSystemProps>`
  max-width: 1080px;
  padding: 0;
  padding-bottom: 70px;
  min-height: 0;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  border: none;
  animation: ${fadeIn} 350ms linear 500ms 1 backwards;
  ${withStyledSystem}
`;

export const FormPageWrapper = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 720px;
  margin-top: 24px;
  animation: ${slideUpFadeIn} 600ms ease-in-out 200ms 1 backwards;
  ${withStyledSystem}
`;

export const DetailPageWrapper = styled.div<StyledSystemProps>`
  padding: 0;
  margin-top: 0;
  max-width: 1080px;
  animation: ${fadeIn} 550ms linear 250ms 1 backwards;
  ${withStyledSystem}
`;

export const InvoiceViewPageWrapper = styled.div<StyledSystemProps>`
  padding: 0;
  margin-top: 0;
  max-width: 1100px;
  animation: ${fadeIn} 550ms linear 250ms 1 backwards;
  ${withStyledSystem}
`;

export const StyledRootLayout = styled(Layout)`
  background-color: ${({ theme }) => theme.colors.grey2};
`;

export const StyledMainLayout = styled(Layout)<{ hidenavbar?: string }>`
  padding-bottom: 32px;
  background-color: ${({ theme }) => theme.colors.grey2};
  min-height: 100vh;

  /* & thead[class="ant-table-thead"] > tr > th {
    background-color: var(--colors-blue1);
    user-select: none;
  } */

  & tbody > tr:last-child > td {
    border-bottom-width: 0;
  }

  @media screen and (min-width: 421px) {
    margin-inline-start: ${({ hidenavbar }) => (hidenavbar === 'true' ? '0' : '80px !important;')};
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    z-index: 0;
    display: block;
    background-image: url(${tilePattern});
    background-size: 554px 925px;
    background-position: top center;
    background-repeat: repeat;
    opacity: 0.085;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
`;

export const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 24px;

  & > * {
    width: 100%;
  }

  @media (min-width: 1080px) {
    margin: 0 36px;
  }

  /* hide side nav for phones */
  @media (max-width: 470px) {
    margin: 0;
  }
`;

export const AlertBox = styled.div`
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`;

export const BorderedView = styled.div`
  position: relative;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid #fff;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0 1px 3px 0 rgba(0, 0, 0, 0.075),
    0 4px 12px 0 rgba(0, 0, 0, 0.1);
`;
