import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M5.65 11.14v7.1h2.61v-7.1L12 14.88l3.74-3.74v7.1h2.6v-7.1l3.74 3.74L24 12.96 17.04 6 12 11.04 6.96 6 0 12.96l1.92 1.92z" />
  </svg>
);
export default SvgLogo;
