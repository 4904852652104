import { defineRecipe } from '@chakra-ui/react';

export const typographyMixins = {
  'Header.H1': {
    fontSize: '40px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '48px',
  },
  'Header.H2': {
    fontSize: '32px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '36px',
  },
  'Header.H3': {
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '30px',
  },
  Subheader: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  MainBody: {
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  SmallBody: {
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  ExtraSmallBody: {
    fontSize: '12px',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: '16px',
  },
};

export const typographyRecipe = defineRecipe({
  variants: {
    variant: typographyMixins,
  },
});
