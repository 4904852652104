import React, { lazy, Suspense } from 'react';

import { AdminRouterParams } from '@m/types';
import { RoleName } from '@m/types/api/iam/Iam';
import { NavLink, Redirect, RouteProps, Switch } from 'react-router-dom';

import { DetailPageWrapper, FormPageWrapper, ListPageWrapper } from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import { navLinkStyle } from 'apps/admin/components/BreadCrumbs/active';
import { DynamicCrumb } from 'apps/admin/components/BreadCrumbs/DynamicCrumb';

const LocationGroupListContainer = lazy(
  () => import('apps/admin/containers/LocationGroup/LocationGroupListContainer'),
);
const CreateLocationGroupContainer = lazy(
  () => import('apps/admin/containers/LocationGroup/CreateLocationGroupContainer'),
);
const LocationGroupDetailContainer = lazy(
  () => import('apps/admin/containers/LocationGroup/LocationGroupDetailContainer'),
);
const EditLocationGroupContainer = lazy(
  () => import('apps/admin/containers/LocationGroup/EditLocationGroupContainer'),
);

const AddZoneContainer = lazy(() => import('apps/admin/components/AddZone/AddZoneContainer'));

const TaxContainer = lazy(() => import('apps/admin/containers/LocationGroup/TaxContainer'));

export const locationGroupRoutesConfig = [
  // Redirects
  // Locations used to be called location groups
  {
    exact: true,
    path: '/location-group',
    component: () => (
      <Redirect
        to={{
          pathname: '/locations',
        }}
      />
    ),
  },
  {
    path: '/location-group/',
    component: ({ location }: RouteProps) => (
      <Redirect
        to={{
          pathname: location
            ? location.pathname.replace('/location-group/', '/locations/')
            : '/locations',
        }}
      />
    ),
  },
  // Routes
  {
    exact: true,
    path: '/locations',
    breadcrumb: 'Locations',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <LocationGroupListContainer />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/locations/create',
    roles: [RoleName.OPERATIONAL_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper>
          <CreateLocationGroupContainer />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/locations/:locationGroupId',
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink to={`/locations/${params.locationGroupId}/details`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="locations" itemId={params.locationGroupId} />
      </NavLink>
    ),
  },
  {
    exact: true,
    path: '/locations/:locationGroupId/details',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <LocationGroupDetailContainer />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/locations/:locationGroupId/add-zone',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <AddZoneContainer />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/locations/:locationGroupId/add-zone/:addZoneId',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <AddZoneContainer isApprovalFlow />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/locations/:locationGroupId/add-zone/:addZoneId/edit',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <AddZoneContainer isEditing />
      </Suspense>
    ),
  },

  {
    exact: true,
    path: '/locations/:locationGroupId/edit',
    breadcrumb: 'Edit',
    roles: [RoleName.OPERATIONAL_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper>
          <EditLocationGroupContainer />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/locations/:locationGroupId/tax/:taxId',
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink
        to={`/locations/${params.locationGroupId}/tax/${params.taxId}`}
        activeStyle={navLinkStyle}
      >
        <DynamicCrumb itemType="tax" itemId={params.taxId} />
      </NavLink>
    ),
  },
  {
    exact: true,
    path: '/locations/:locationGroupId/tax/:taxId',
    breadcrumb: 'Edit',
    roles: [RoleName.OPERATIONAL_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <TaxContainer />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
];

export default function LocationGroupRoutes() {
  return (
    <Switch>
      {locationGroupRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
